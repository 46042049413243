








import { Component, Mixins } from 'vue-property-decorator';
import { View } from 'client-website-ts-library/plugins';

@Component
export default class ViewAppraisal extends Mixins(View) {
  iframeUrl = ''

  mounted() {
    const docId = this.$route.params.id;

    const pdfUrl = `https://prolist.net.au/WebServices/DocumentBuilderService.asmx/GetPDF?documentId=${docId}`;

    this.iframeUrl = `https://prolist.net.au/controls/DocumentBuilder/builder.html?DocumentId=${docId}&ViewMode=true&Embed=true&PDFUrl=${encodeURIComponent(pdfUrl)}`;
  }
}
